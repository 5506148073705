import axios from '../utils/axiosConfig'; 
import { API_BASE_URL, WS_BASE_URL, IS_LOCAL_DEV } from '../utils/config';
import { GetDecryptedString, decodeJWT } from '../utils/encryption'; // 新增導入

const BASE_URL = `${API_BASE_URL}/ctrag`;
const GOOGLE_API_BASE_URL = `${API_BASE_URL}/google`;
const DRIVE_API_BASE_URL = `${GOOGLE_API_BASE_URL}/drive`;

// 快取相關變數
const TOKEN_BUFFER_TIME = 5 * 60 * 1000; // 5分鐘的緩衝時間
const STORAGE_KEYS = {
  GOOGLE_TOKEN: 'cached_google_token',
  TOKEN_EXPIRATION: 'google_token_expiration'
};

// 快取相關函數
const getCachedToken = () => {
  try {
    return {
      token: localStorage.getItem(STORAGE_KEYS.GOOGLE_TOKEN),
      expiration: parseInt(localStorage.getItem(STORAGE_KEYS.TOKEN_EXPIRATION))
    };
  } catch (error) {
    console.error('讀取快取 token 失敗:', error);
    return { token: null, expiration: null };
  }
};

const setCachedToken = (token, expiration) => {
  try {
    localStorage.setItem(STORAGE_KEYS.GOOGLE_TOKEN, token);
    localStorage.setItem(STORAGE_KEYS.TOKEN_EXPIRATION, expiration.toString());
  } catch (error) {
    console.error('儲存快取 token 失敗:', error);
  }
};

export const checkChecksumsExist = async (checksums) => {
  try {
    const response = await axios.post(`${BASE_URL}/check_checksums_exist`, { checksums });
    return response.data;
  } catch (error) {
    console.error('檢查校驗和失敗:', error);
    throw error;
  }
};

export const dispatch = async (dispatchPayload) => {
  try {
    const response = await axios.post(`${BASE_URL}/dispatch`, {
      collection: dispatchPayload.collection,
      retrieval: dispatchPayload.retrieval,
      transformation: dispatchPayload.transformation,
      augmentation: dispatchPayload.augmentation,
      generation: dispatchPayload?.generation,
    });
    return response.data;
  } catch (error) {
    console.error('分發任務失敗:', error);
    throw error;
  }
};

// Update the chat function to handle streaming
export const chat = async (chatPayload) => {
  try {
    const response = await fetch(`${BASE_URL}/chat`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(chatPayload),
    });

    if (!response.ok) {
      throw new Error('Chat request failed');
    }

    // Return the response object directly for streaming
    return response;
  } catch (error) {
    console.error('Chat request failed:', error);
    throw error;
  }
};

// WebSocket chat function
export const wsChat = () => {
  const token = GetDecryptedString(window.localStorage.getItem('token'));
  let clearToken = token.replace('Bearer ', '');

  let ws = null;
  let messageCallback = null;
  let errorCallback = null;
  let isConnected = false;
  let reconnectAttempts = 0;
  const maxReconnectAttempts = 3;
  let messageQueue = [];
  let connectPromise = null;

  const connect = () => {
    if (connectPromise) return connectPromise;

    connectPromise = new Promise((resolve, reject) => {
      try {
        ws = new WebSocket(`${WS_BASE_URL}?token=${clearToken}`);
        ws.onopen = () => {
          console.debug('WebSocket 連線已建立');
          isConnected = true;
          reconnectAttempts = 0;
          if (IS_LOCAL_DEV && WS_BASE_URL.includes('localhost')) {
            // 初始化連線時傳送 token
            ws.send(JSON.stringify({ token }));
          }
          resolve(ws);
          
          // 處理排隊的消息
          while (messageQueue.length > 0) {
            const { message, resolve: queuedResolve } = messageQueue.shift();
            try {
              message.token = clearToken;
              ws.send(JSON.stringify(message));
              queuedResolve();
            } catch (error) {
              console.error('Failed to send queued message:', error);
            }
          }
        };

        ws.onclose = (event) => {
          console.debug('WebSocket connection closed:', event);
          isConnected = false;
          connectPromise = null;
          
          if (event.code !== 1000) { // 非正常關閉才重連
            // 嘗試重新連接
            if (reconnectAttempts < maxReconnectAttempts) {
              reconnectAttempts++;
              setTimeout(() => {
                console.debug(`Attempting to reconnect (${reconnectAttempts}/${maxReconnectAttempts})`);
                connect();
              }, 1000 * Math.pow(2, reconnectAttempts)); // 指數退避
            }
          }
        };

        ws.onerror = (error) => {
          console.error('WebSocket error:', error);
          isConnected = false;
          if (errorCallback) {
            errorCallback(error);
          }
          reject(error);
        };

        ws.onmessage = (event) => {
          if (event.data === 'null') return;
          
          try {
            const data = JSON.parse(event.data);
            if (messageCallback) {
              messageCallback(data);
            }
          } catch (error) {
            console.error('Error parsing WebSocket message:', error);
            if (errorCallback) {
              errorCallback(new Error('Failed to parse message'));
            }
          }
        };

      } catch (error) {
        console.error('WebSocket connection error:', error);
        connectPromise = null;
        reject(error);
      }
    });

    return connectPromise;
  };

  // 初始連接
  connect();

  return {
    // 發送消息
    sendMessage: async (chatPayload) => {
      // 如果沒有連接，先嘗試連接
      if (!isConnected || !ws || ws.readyState !== WebSocket.OPEN) {
        return new Promise((resolve, reject) => {
          messageQueue.push({
            message: chatPayload,
            resolve,
            reject
          });
          
          // 嘗試重新連接
          connect().catch(reject);
        });
      }

      // 如果已經連接，直接發送
      return new Promise((resolve, reject) => {
        try {
          chatPayload.token = clearToken;
          ws.send(JSON.stringify(chatPayload));
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    },

    // 設置消息處理回調
    onMessage: (callback) => {
      messageCallback = callback;
    },

    // 設置錯誤處理回調
    onError: (callback) => {
      errorCallback = callback;
    },

    // 關閉連接
    close: () => {
      if (ws) {
        isConnected = false;
        ws.close(1000, "Normal closure"); // 使用正常關閉代碼
      }
    },

    // 檢查連接狀態
    isConnected: () => {
      return isConnected && ws && ws.readyState === WebSocket.OPEN;
    },

    // 重新連接
    reconnect: () => {
      if (ws) {
        ws.close();
      }
      return connect();
    }
  };
};




export const getRelatedQuestions = async (chatSessionId) => {
  try {
    const response = await axios.get(`${BASE_URL}/get_related_questions/${chatSessionId}`);
    return response.data;
  } catch (error) {
    console.error('獲取相關問題失敗:', error);
    throw error;
  }
};


export const getEventDetails = async (eventId) => {
  try {
    const response = await axios.get(`${BASE_URL}/event/${eventId}`);
    return response.data;
  } catch (error) {
    console.error('獲取事件詳情失敗:', error);
    throw error;
  }
};

export const getEventStatus = async (eventId) => {
  try {
    const response = await axios.get(`${BASE_URL}/event_status/${eventId}`);
    return response.data;
  } catch (error) {
    console.error('獲取事件狀態失敗:', error);
    throw error;
  }
};

export const getEventStatusDetail = async (eventId) => {
  try {
    const response = await axios.get(`${BASE_URL}/event_status_detail/${eventId}`);
    return response.data;
  } catch (error) {
    console.error('獲取事件狀態詳情失敗:', error);
    throw error;
  }
};

export const getResult = async (eventId) => {
  try {
    const response = await axios.get(`${BASE_URL}/result/${eventId}`);
    return response.data;
  } catch (error) {
    console.error('獲取結果失敗:', error);
    throw error;
  }
};

export const getResultDetail = async (eventId) => {
  try {
    const response = await axios.get(`${BASE_URL}/result_detail/${eventId}`);
    return response.data;
  } catch (error) {
    console.error('獲取詳細結果失敗:', error);
    throw error;
  }
};

export const embedding = async (embeddingRequest) => {
  try {
    const response = await axios.post(`${BASE_URL}/embedding`, embeddingRequest);
    return response.data;
  } catch (error) {
    console.error('嵌入請求失敗:', error);
    throw error;
  }
};

// 新增獲取使用者聊天會話列表的函數
export const getChatSessionsByUserEmail = async (page = 1, page_size = 10) => {
  try {
    const response = await axios.get(`${BASE_URL}/chat_session`, {
      params: {
        page: page,        // 新增 page 參數
        pageSize: page_size,   // 新增 page_size 參數
      },
    });
    return response.data;
  } catch (error) {
    console.error('獲取聊天會話列表失敗:', error);
    throw error;
  }
};

// 修改 getChatSessionHistoryById 函數，加入 page 和 page_size 參數
export const getChatSessionHistoryById = async (chatSessionId, contextWindowLength, page = 1, page_size = 10) => {
  try {
    const response = await axios.get(`${BASE_URL}/chat_session_history/${chatSessionId}`, {
      params: {
        context_window_length: contextWindowLength,
        page: page,          // 新增 page 參數
        pageSize: page_size // 新增 page_size 參數
      },
    });
    return response.data.chat_histories;
  } catch (error) {
    console.error('獲取聊天歷史記錄失敗:', error);
    throw error;
  }
};

// 新增知識庫
export const createLLMKnowledgeBase = async (data) => {
  try {
    const response = await axios.post(`${BASE_URL}/llm_knowledge_base`, data);
    return response.data;
  } catch (error) {
    console.error('新增知識庫失敗:', error);
    throw error;
  }
};

// 獲取知識庫
export const getLLMKnowledgeBase = async (params) => {
  try {
    const response = await axios.get(`${BASE_URL}/llm_knowledge_base`, {
      params: params,
    });
    return response.data;
  } catch (error) {
    console.error('獲取知識庫失敗:', error);
    throw error;
  }
};

// 獲取使用者可使用的知識庫
export const getLLMKnowledgeBaseByUser = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/llm_knowledge_base/user`);
    return response.data;
  } catch (error) {
    console.error('獲取使用者知識庫失敗:', error);
    throw error;
  }
};

// 獲取使用者管理的知識庫
export const getLLMKnowledgeBaseByManager = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/llm_knowledge_base/manager`);
    return response.data;
  } catch (error) {
    console.error('獲取管理者知識庫失敗:', error);
    throw error;
  }
};


// 更新或插入知識庫
export const upsertLLMKnowledgeBase = async (data) => {
  try {
    const response = await axios.put(`${BASE_URL}/llm_knowledge_base`, data);
    return response.data;
  } catch (error) {
    console.error('更新或插入知識庫失敗:', error);
    throw error;
  }
};

// 刪除知識庫
export const removeLLMKnowledgeBase = async (knowledgeBaseName) => {
  try {
    const response = await axios.delete(`${BASE_URL}/llm_knowledge_base`, {
      params: { knowledgeBaseName: knowledgeBaseName },
    });
    return response.data;
  } catch (error) {
    console.error('刪除知識庫失敗:', error);
    throw error;
  }
};

// 更新或插入知識庫的 RAG chunk text
export const upsertLLMKnowledgeRAGChunkText = async ({ knowledge_base_name, knowledge_base_id, chunk_text }) => {
  try {
    const response = await axios.put(
      `${BASE_URL}/llm_knowledge_base/upsert_knowledge_rag_chunk_text`,
      {
        knowledge_base_name,
        collection: knowledge_base_id,
        chunk_text
      }
    );
    return response.data;
  } catch (error) {
    console.error('更新或插入知識庫 RAG chunk text 失敗:', error);
    throw error;
  }
};

// force update knowledge base
export const upsertLLMKnowledgeRAGDrive = async (knowledge_base_name) => {
  try {
    const response = await axios.put(`${BASE_URL}/llm_knowledge_base/upsert_knowledge_rag_drive`, {
      knowledge_base_name: knowledge_base_name
    });
    return response.data;
  } catch (error) {
    console.error('強制更新知識庫失敗:', error);
    throw error;
  }
};



// 修改 getGoogleToken 函數
export const getGoogleToken = async () => {
  try {
    // 檢查是否有有效的快取 token
    const { token, expiration } = getCachedToken();
    if (token && expiration && Date.now() < expiration) {
      return token;
    }

    const response = await axios.get(`${GOOGLE_API_BASE_URL}/get-google-token`);
    const shortTermToken = response.data.short_term_token;
    const decodedToken = decodeJWT(shortTermToken);
    if (!decodedToken) {
      throw new Error('無法解碼 JWT token');
    }

    // 更新快取
    const newToken = decodedToken.google_access_token;
    const newExpiration = decodedToken.exp * 1000 - TOKEN_BUFFER_TIME;
    setCachedToken(newToken, newExpiration);

    return newToken;
  } catch (error) {
    console.error('獲取Google Token失敗:', error);
    if (error.response && error.response.status === 404) {
      throw new Error('找不到用戶的憑證。請先登錄。');
    } else {
      throw error;
    }
  }
};


// 獲取多個文件或文件夾
export const getFiles = async (fileIds) => {
  try {
    const response = await axios.post(`${DRIVE_API_BASE_URL}/files`, {
      file_ids: fileIds
    });
    return response.data;
  } catch (error) {
    console.error('獲取文件失敗:', error);
    throw error;
  }
};
// 獲取多個文件或文件夾的詳細信息
export const getDriveFiles = async (fileIds) => {
  try {
    const response = await axios.post(`${BASE_URL}/drive_files`, {
      file_ids: fileIds
    });

    // 檢查每個文件的狀態
    for (const file of response.data) {
      if (file.status === 3) {
        throw new Error(`已達到文件數量限制：文件 ID ${file.id}`);
      } else if (file.status === 2) {
        throw new Error(`獲取文件 '${file.name}' 失敗: ${file.message}`);
      }
    }

    return response.data;
  } catch (error) {
    console.error('獲取Google Drive文件詳細信息失敗:', error);
    if (error.response) {
      switch (error.response.status) {
        case 400:
          throw new Error('未提供文件ID');
        case 404:
          throw new Error('找不到指定的文件');
        default:
          throw new Error(`獲取文件失敗: ${error.response.data.detail || '未知錯誤'}`);
      }
    } else {
      throw new Error('網絡錯誤，請稍後再試');
    }
  }
};

// 更新聊天回應的評分
export const updateChatThumb = async (chatCompletionId, thumbs) => {
  try {
    const response = await axios.post(`${BASE_URL}/thumbs`, {
      chat_completion_id: chatCompletionId,
      thumbs: thumbs
    });
    return response.data;
  } catch (error) {
    console.error('更新評分失敗:', error);
    if (error.response) {
      switch (error.response.status) {
        case 400:
          throw new Error('無效的評分請求');
        case 404:
          throw new Error('找不到指定的聊天回應');
        default:
          throw new Error(`更新評分失敗: ${error.response.data.detail || '未知錯誤'}`);
      }
    } else {
      throw new Error('網絡錯誤，請稍後再試');
    }
  }
};

// 獲取指定知識庫的聊天歷史記錄
export const getChatSessionsByKnowledgeBase = async (knowledgeBaseId, searchText = "", titleSearch = "", page = 1, pageSize = 10) => {
  try {
    if (page < 1) {
      throw new Error('頁碼必須大於等於1');
    }
    if (pageSize < 1 || pageSize > 100) {
      throw new Error('每頁顯示的項目數量必須在1到100之間');
    }

    const response = await axios.get(`${BASE_URL}/chat_history_by_knowledge_base_id/${knowledgeBaseId}`, {
      params: {
        page,
        pageSize,
        search_text: searchText,
        title_search: titleSearch
      }
    });

    // 如果沒有聊天歷史記錄，返回空數組和總數為0
    if (!response.data.chat_histories) {
      return {
        chat_histories: [],
        total: 0
      };
    }

    return {
      chat_histories: response.data.chat_histories,
      total: response.data.total
    };
  } catch (error) {
    console.error('獲取知識庫聊天歷史記錄失敗:', error);
    if (error.response) {
      switch (error.response.status) {
        case 404:
          throw new Error('找不到指定的知識庫');
        case 500:
          throw new Error(`取得聊天歷史時發生錯誤: ${error.response.data.detail}`);
        default:
          throw new Error(`獲取聊天歷史記錄失敗: ${error.response.data.detail || '未知錯誤'}`);
      }
    } else {
      throw new Error('網絡錯誤，請稍後再試');
    }
  }
};


