import React from 'react';
import { Select } from 'antd';
import ISO6391 from 'iso-639-1';

const LanguageSelector = ({ value, onChange, ...props }) => {
    const languageOptions = React.useMemo(() => {
        const languages = ISO6391.getAllNames();
        const codes = ISO6391.getAllCodes();
        
        // 基本語言選項
        const options = languages.map((name, index) => {
            const code = codes[index];
            const nativeName = ISO6391.getNativeName(code);
            
            // 特別處理簡體中文的顯示
            if (code === 'zh') {
                return {
                    value: code,
                    label: `Chinese (简体中文)`
                };
            }
            
            return {
                value: code,
                label: nativeName ? `${name} (${nativeName})` : name
            };
        });

        // 加入繁體中文
        options.push({
            value: 'zh-TW',
            label: `Traditional Chinese (繁體中文)`
        });

        return options;
    }, []);

    return (
        <Select
            value={value}
            onChange={onChange}
            options={languageOptions}
            showSearch
            filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            {...props}
        />
    );
};

export default LanguageSelector; 