import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Form, Input, Select, Button, Checkbox, InputNumber, message, Divider } from 'antd';
import { uploadAIToolsMaterial } from '../../api/s3';
import { getGoogleToken, getDriveFiles } from '../../api/ctrag';
import { meetingSummarization } from '../../api/aiTools';
import './meetingSummaryForm.scss';
import SingleUpload from '../../components/SingleUpload';
import LanguageSelector from '../../components/LanguageSelector';

const { TextArea } = Input;

const MeetingSummaryForm = ({ visible, onCancel, onAfterSubmit, user }) => {
  const [form] = Form.useForm();
  const [selectedLanguage, setSelectedLanguage] = useState('zh-TW');
  const [audioFile, setAudioFile] = useState(null);
  const [attachmentFile, setAttachmentFile] = useState(null);
  const [isAudioUploading, setIsAudioUploading] = useState(false);
  const [isAttachmentUploading, setIsAttachmentUploading] = useState(false);

  const loadPicker = useCallback(() => {
    window.gapi.load('picker', {
      callback: () => {
        // Picker API 加載完成
      },
    });
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://apis.google.com/js/api.js';
    script.onload = () => loadPicker();
    document.body.appendChild(script);
  }, [loadPicker]);

  const createPicker = async (fileType) => {
    try {
      const googleAccessToken = await getGoogleToken();

      if (!googleAccessToken) {
        message.error('無法獲取 Google Access Token');
        if (fileType === 'audio') {
          setIsAudioUploading(false);
        } else {
          setIsAttachmentUploading(false);
        }
        return;
      }

      const allowedMimeTypes = fileType === 'audio' 
        ? ['audio/mpeg', 'audio/aac', 'video/mp4', 'video/x-matroska'].join(',')
        : ['application/vnd.openxmlformats-officedocument.presentationml.presentation', 
           'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
           'application/pdf',
           'text/plain'].join(',');

      const picker = new window.google.picker.PickerBuilder()
        .setOAuthToken(googleAccessToken)
        .setDeveloperKey(process.env.REACT_APP_GOOGLE_API_KEY)
        .setCallback((data) => pickerCallback(data, fileType))
        .enableFeature(window.google.picker.Feature.SUPPORT_TEAM_DRIVES)
        .addView(
          new window.google.picker.DocsView()
            .setEnableTeamDrives(true)
            .setIncludeFolders(true)
            .setMode(window.google.picker.DocsViewMode.LIST)
            .setMimeTypes(allowedMimeTypes)
        )
        .setSelectableMimeTypes(allowedMimeTypes)
        .setLocale('zh-TW')
        .build();
      picker.setVisible(true);
    } catch (error) {
      console.error('創建 Google Picker 失敗:', error);
    }
  };

  const pickerCallback = async (data, fileType) => {
    if (data.action === window.google.picker.Action.PICKED) {
      const file = data.docs[0];
      await handleDriveFile(file, fileType);
    } else {
      if (fileType === 'audio') {
        setIsAudioUploading(false);
      } else {
        setIsAttachmentUploading(false);
      }
    }
  };

  const handleDriveFile = async (file, fileType) => {
    const newFile = {
      id: file.id,
      source_name: file.name,
      source_type: file.mimeType,
      source_source: 'drive',
      source_url: file.url,
      source_id: file.id,
      dispatchStatus: 'pending',
      dispatchMessage: '',
      status: 1,
    };

    try {
      if (fileType === 'audio') {
        setIsAudioUploading(true);
      } else {
        setIsAttachmentUploading(true);
      }
      const fileDetails = await getDriveFiles([file.id]);
      if (fileDetails && fileDetails.length > 0) {
        const detailedFile = fileDetails[0].file_info;
        const updatedFile = {
          ...newFile,
          source_name: detailedFile.name,
          source_type: detailedFile.mimeType,
          source_url: detailedFile.webViewLink,
          size: detailedFile.size,
          createdTime: detailedFile.createdTime,
          status: detailedFile.status || 1,
        };

        if (fileType === 'audio') {
          if (audioFile) {
            message.warning('只能上傳一個音檔，新檔案將取代舊檔案');
          }
          setAudioFile(updatedFile);
          form.setFieldValue('media_path', updatedFile);
        } else {
          if (attachmentFile) {
            message.warning('只能上傳一個附件，新檔案將取代舊檔案');
          }
          setAttachmentFile(updatedFile);
          form.setFieldValue('material_path', updatedFile);
        }
      }
    } catch (error) {
      console.error('處理 Google Drive 文件選擇失敗:', error);
      message.error(`處理 Google Drive 文件失敗: ${error.message}`);
    } finally {
      if (fileType === 'audio') {
        setIsAudioUploading(false);
      } else {
        setIsAttachmentUploading(false);
      }
    }
  };

  const handleAudioFileChange = async (info) => {
    const file = info.file;
    if (audioFile) {
      message.warning('只能上傳一個音檔，新檔案將取代舊檔案');
    }

    setIsAudioUploading(true);

    try {
      const result = await uploadAIToolsMaterial([file], user.email);
      
      const newFile = { 
        source_name: file.name,
        source_type: file.type,
        source_source: 'local',
        source_url: result[0].source_info.s3_url
      };
      
      setAudioFile(newFile);
      form.setFieldValue('media_path', newFile);
      message.success('音檔上傳成功');
    } catch (error) {
      console.error('音檔上傳失敗:', error);
      message.error('音檔上傳失敗');
    } finally {
      setIsAudioUploading(false);
    }
  };

  const handleAttachmentFileChange = async (info) => {
    const file = info.file;
    if (attachmentFile) {
      message.warning('只能上傳一個附件，新檔案將取代舊檔案');
    }

    setIsAttachmentUploading(true);

    try {
      const result = await uploadAIToolsMaterial([file], user.email);
      
      const newFile = { 
        source_name: file.name,
        source_type: file.type,
        source_source: 'local',
        source_url: result[0].source_info.s3_url
      };
      
      setAttachmentFile(newFile);
      form.setFieldValue('material_path', newFile);
      message.success('附件上傳成功');
    } catch (error) {
      console.error('附件上傳失敗:', error);
      message.error('附件上傳失敗');
    } finally {
      setIsAttachmentUploading(false);
    }
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      
      // 檢查是否有上傳音檔，且不在上傳中
      if (!audioFile || !values.media_path) {
        message.error('請上傳音檔');
        return;
      }

      if (isAudioUploading || isAttachmentUploading) {
        message.warning('請等待檔案上傳完成');
        return;
      }

      const formattedValues = {
        media_path: audioFile.source_url,
        material_path: attachmentFile ? attachmentFile.source_url : null,
        additional_context: values.additional_context,
        target_language: values.target_language,
        required_topics: values.required_topics,
        ai_generate_count: values.ai_generate_count,
        generate_action_items: values.generate_options?.includes('action_items') || false,
        generate_executive_summary: values.generate_options?.includes('executive_summary') || false,
        output_format: values.output_format
      };
      const model = {
        model_provider: values.llm_model_provider || 'openai',
        model_level: values.llm_model_level || 'basic'
      }

      const result = await meetingSummarization({data: formattedValues, model: model});
      
      if (result.status === 200) {
        message.success('已成功送出會議記錄請求');
        onAfterSubmit(result);
        form.resetFields();
        setAudioFile(null);
        setAttachmentFile(null);
      } else {
        message.error(result.message || '送出會議記錄請求失敗');
      }
    } catch (error) {
      if (error.errorFields) {
        message.error('請檢查表單填寫是否完整');
      } else {
        console.error('提交表單時發生錯誤:', error);
        message.error('處理請求時發生錯誤: ' + (error.message || '未知錯誤'));
      }
    }
  };

  return (
    <Modal
      className="meeting-summary-modal"
      title="新增會議記錄"
      open={visible}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          取消
        </Button>,
        <Button disabled={isAudioUploading || isAttachmentUploading} key="submit" type="primary" onClick={handleSubmit}>
          開始執行
        </Button>
      ]}
      centered
      width={800}
    >
      <Form
        form={form}
        layout="vertical"
      >
        {/* 要分析的影音檔 */}
        <Form.Item
          label="要分析的影音檔 (.mp3, .aac, .mp4, .mkv)"
          name="media_path"
          rules={[{ required: true, message: '請上傳影音檔' }]}
          valuePropName="file"
        >
          <SingleUpload
            file={audioFile}
            isUploading={isAudioUploading}
            onFileChange={handleAudioFileChange}
            onGoogleDriveClick={() => createPicker('audio')}
            onDeleteFile={() => {
              setAudioFile(null);
              form.setFieldValue('media_path', null);
            }}
            acceptTypes=".mp3,.aac,.mp4,.mkv"
            disabled={isAudioUploading}
          />
        </Form.Item>

        {/* 會議附件 */}
        <Form.Item
          label="會議附件 (pptx, docx, pdf, txt)"
          name="material_path"
          valuePropName="file"
        >
          <SingleUpload
            file={attachmentFile}
            isUploading={isAttachmentUploading}
            onFileChange={handleAttachmentFileChange}
            onGoogleDriveClick={() => createPicker('attachment')}
            onDeleteFile={() => {
              setAttachmentFile(null);
              form.setFieldValue('material_path', null);
            }}
            acceptTypes=".pptx,.docx,.pdf,.txt"
            disabled={isAttachmentUploading}
          />
        </Form.Item>

        {/* 補充會議資訊 */}
        <Form.Item
          label="補充會議資訊"
          name="additional_context"
        >
          <TextArea
            placeholder="針對這份會議的內容，可提供額外的補充資訊，讓分析的結果更準確。(Optional)"
            rows={4}
          />
        </Form.Item>

        {/* 目標語言 */}
        <Form.Item
          label="目標語言"
          name="target_language"
          initialValue="zh-TW"
        >
          <LanguageSelector
            value={selectedLanguage}
            onChange={value => setSelectedLanguage(value)}
          />
        </Form.Item>

        <Divider />

        {/* 希望包含的總結項目 */}
        <Form.Item
          label="希望包含的總結項目"
          name="required_topics"
        >
          <Select
            mode="tags"
            placeholder="針對這次會議，希望包含的總結項目"
          />
        </Form.Item>

        {/* AI 判斷生成 */}
        <Form.Item
          label="AI 判斷生成"
          style={{ marginBottom: 0 }}
        >
          <Form.Item
            name="ai_generate"
            valuePropName="checked"
            style={{ display: 'inline-block', marginRight: 8 }}
          >
            <Checkbox></Checkbox>
          </Form.Item>
          <Form.Item
            shouldUpdate={(prevValues, currentValues) => prevValues.ai_generate !== currentValues.ai_generate}
            style={{ display: 'inline-block', width: '150px' }}
          >
            {({ getFieldValue }) => (
              <Form.Item
                name="ai_generate_count"
                initialValue={1}
                noStyle
              >
                <InputNumber
                  min={1}
                  max={10}
                  addonAfter="個新題目"
                  disabled={!getFieldValue('ai_generate')}
                />
              </Form.Item>
            )}
          </Form.Item>
        </Form.Item>

        <Divider />

        {/* 建立選項 */}
        <Form.Item
          name="generate_options"
        >
          <Checkbox.Group>
            <Checkbox value="action_items">建立行動項目 (Action Items)</Checkbox>
            <Checkbox value="executive_summary">建立 Executive Summary</Checkbox>
          </Checkbox.Group>
        </Form.Item>

        <div style={{ display: 'flex', gap: '16px' }}>
          <Form.Item style={{ width: '120px' }} label="預設模型提供者" name="llm_model_provider" initialValue="openai">
              <Select>
                <Select.Option value="openai">OpenAI</Select.Option>
                <Select.Option value="anthropic">Anthropic</Select.Option>
              </Select>
          </Form.Item>

          <Form.Item style={{ width: '120px' }} label="預設模型等級" name="llm_model_level" initialValue="advanced">
              <Select>
                <Select.Option value="basic">基礎版</Select.Option>
                <Select.Option value="medium">中階版</Select.Option>
                <Select.Option value="advanced">進階版</Select.Option>
              </Select>
          </Form.Item>

          {/* 輸出格式 */}
          <Form.Item
            label="輸出格式"
            name="output_format"
            style={{ width: '150px' }}
            rules={[{ required: true, message: '請選擇輸出格式' }]}
          >
            <Select placeholder="請選擇輸出格式">
              <Select.Option value="md">Markdown</Select.Option>
              <Select.Option value="docx">Word (DOCX)</Select.Option>
              <Select.Option value="txt">純文字 (TXT)</Select.Option>
              {/* <Select.Option value="html">HTML</Select.Option>
              <Select.Option value="pdf">PDF</Select.Option> */}
            </Select>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default MeetingSummaryForm;
