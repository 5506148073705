/*
    Due to "npm run start" will access backend api through api gateway,
    so we need to add "/aidp" to the api base url when not in dev environment
*/
const IS_LOCAL_DEV = process.env.REACT_APP_IS_DEV;
const API_BASE_URL = `${process.env.REACT_APP_APIGATEWAY_URL}${!IS_LOCAL_DEV ? '/aidp' : ''}`;
const IS_SERVER_DEV = process.env.REACT_APP_ENV === 'development';
const IS_PRODUCTION = process.env.REACT_APP_ENV === 'production';

// you can also use wss directly in dev environment, but it will connect to develop backend server not localhost
const WS_BASE_URL = IS_LOCAL_DEV ? 'ws://localhost:12090/api/ctrag/ws/chat'
// development
: IS_SERVER_DEV ? 'wss://5s6sqe3yfh.execute-api.ap-northeast-1.amazonaws.com/api/'
// production
: 'wss://zd5jfiozvk.execute-api.ap-northeast-1.amazonaws.com/api/';

const N8N_KNOWLEDGE_BASE_WORKFLOW_URL = IS_LOCAL_DEV ? 'https://n8n.rfdme.io/webhook-test/dev/llm/knowledge/start'
: IS_SERVER_DEV ? 'https://n8n.rfdme.io/webhook/dev/llm/knowledge/start'
: 'https://n8n.rfdme.io/webhook/llm/knowledge/start';

module.exports = {
  IS_LOCAL_DEV,
  API_BASE_URL,
  IS_SERVER_DEV,
  IS_PRODUCTION,
  WS_BASE_URL,
  N8N_KNOWLEDGE_BASE_WORKFLOW_URL
};