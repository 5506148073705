import React, { useState, useContext, useEffect, useCallback } from 'react';
import { Button, Progress, Space, Table, message, Tooltip, Modal, Tag } from 'antd';
import { DownloadOutlined, DeleteOutlined } from '@ant-design/icons';
import ISO6391 from 'iso-639-1';
import './aiAssistant.scss';
import MeetingSummaryForm from './meetingSummaryForm';
import TranslationForm from './translationForm';
import AuthContext from '../../contexts/authProvider';
import { getAIToolHistoryByUserEmail, deleteAIToolHistory, AI_ASSISTANT_TYPES, AI_ASSISTANT_FILE_TYPES, getEventStatus } from '../../api/aiTools';
import { formatTimestamp, getDocumentType } from '../../utils/functionTool';

const getTypeConfig = (type) => {
  const configs = {
    [AI_ASSISTANT_TYPES.MEETING_SUMMARY]: {
      title: '微電智能助理 / 會議逐字稿 & 智能解析',
      processingTitle: '正在處理中',
      completedTitle: '已完成的項目',
      downloadButtons: [
        { text: '下載逐字稿', key: 'transcript' },
        { text: '下載分析', key: 'analysis' }
      ]
    },
    [AI_ASSISTANT_TYPES.DOCUMENT_TRANSLATION]: {
      title: '微電智能助理 / 文件翻譯',
      processingTitle: '翻譯處理中',
      completedTitle: '已完成翻譯',
      downloadButtons: [
        { text: '下載翻譯', key: 'translation' }
      ]
    }
  };
  return configs[type];
};

const getLanguageDisplay = (languageCode) => {
  if (languageCode === 'zh-TW') {
    return '繁體中文 (Traditional Chinese)';
  }
  const code = languageCode.split('-')[0];
  const name = ISO6391.getName(code);
  const nativeName = ISO6391.getNativeName(code);
  return nativeName ? `${name} (${nativeName})` : name;
};

const AIAssistant = ({ type = AI_ASSISTANT_TYPES.MEETING_SUMMARY }) => {
  const { user } = useContext(AuthContext);
  const config = getTypeConfig(type);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const pageSize = 10;

  const [isModalVisible, setIsModalVisible] = useState(false);

  const fetchData = useCallback(async (currentPage = 1, isLoadMore = false) => {
    setLoading(true);
    try {
      const response = await getAIToolHistoryByUserEmail({
        page: currentPage,
        pageSize,
        userEmail: user?.email,
        eventType: type
      });
      
      // 處理資料分組和排序
      const processingItems = [];
      const completedItems = [];
      
      response.forEach(item => {
        const isProcessing = item.task?.task_progress?.status === 'PROCESSING' || item.task?.task_progress?.status === undefined;
        const processedItem = {
          ...item,
          status: isProcessing ? 'PROCESSING' : 'COMPLETED',
          group: isProcessing ? '處理中項目' : '已完成項目'
        };
        
        if (isProcessing) {
          processingItems.push(processedItem);
        } else {
          completedItems.push(processedItem);
        }
      });
      
      // 已完成項目按時間排序
      completedItems.sort((a, b) => new Date(b.event_timestamp) - new Date(a.event_timestamp));
      
      // 合併處理中和已完成的項目
      const processedData = [...processingItems, ...completedItems];
      
      setData(prev => isLoadMore ? [...prev, ...processedData] : processedData);
      setHasMore(processedData.length === pageSize);
    } catch (error) {
      message.error('獲取資料失敗');
    } finally {
      setLoading(false);
    }
  }, [type, user?.email]);

  const updateProcessingItems = useCallback(async () => {
    const processingItems = data.filter(item => item.status === 'PROCESSING');
    if (processingItems.length === 0) return;

    try {
      const updatedItems = await Promise.all(
        processingItems.map(async (item) => {
          const status = await getEventStatus(item.event_id);
          return {
            ...item,
            task: {
              ...item.task,
              task_progress: {
                status: status?.event_status,
                message: status?.event_message,
                progress: status?.event_progress
              }
            },
            status: status?.event_status
          };
        })
      );

      setData(prevData => {
        const newData = [...prevData];
        updatedItems.forEach(updatedItem => {
          const index = newData.findIndex(item => item.event_id === updatedItem.event_id);
          if (index !== -1) {
            newData[index] = updatedItem;
          }
        });
        return newData;
      });

      // 如果所有處理中的項目都完成了，重新獲取完整列表
      if (updatedItems.every(item => item.status !== 'PROCESSING')) {
        fetchData(1, false);
      }
    } catch (error) {
      console.error('更新處理中項目狀態失敗:', error);
    }
  }, [data, fetchData]);

  useEffect(() => {
    // 重置狀態並獲取數據
    setData([]);
    setPage(1);
    setHasMore(true);
    fetchData(1, false);
  }, [fetchData]);

  useEffect(() => {
    const timer = setInterval(updateProcessingItems, 5000);
    
    // existing cleanup...
    return () => {
      clearInterval(timer);
    };
  }, [updateProcessingItems]);

  const handleLoadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    fetchData(nextPage, true);
  };

  const handleCancel = (record) => {
    Modal.confirm({
      title: '確認取消',
      content: '確定要終止處理並刪除該紀錄嗎？',
      okText: '確定',
      cancelText: '取消',
      centered: true,
      onOk: async () => {
        await deleteAIToolHistory(record.event_id);
        message.success('已取消處理並刪除紀錄');
        fetchData();
      }
    });
  };

  const getColumns = (isCompleted) => [
    {
      title: '檔案名稱',
      width: '25%',
      dataIndex: ['data', `${type === AI_ASSISTANT_TYPES.MEETING_SUMMARY ? 'media_path' : 'material_path'}`],
      key: 'file_name',
      render: (mediaPath) => {
        if (!mediaPath) return '-';
        return mediaPath.split('/').pop();
      }
    },
    {
      title: isCompleted ? '' : '狀態',
      width: '50%',
      dataIndex: 'status',
      key: 'status',
      render: (status, record) => {
        if (status === 'PROCESSING') {
          return (
            <Space style={{ width: '100%', justifyContent: 'space-between' }}>
              <Space>
                {type === AI_ASSISTANT_TYPES.DOCUMENT_TRANSLATION && (
                  <Tag color="blue">{getLanguageDisplay(record.data.target_language)}</Tag>
                )}
                <Tooltip title={record.task?.task_progress?.message || '處理中...'}>
                  <Progress 
                    percent={record.task?.task_progress?.progress || 0} 
                    strokeColor={{
                      '0%': '#108ee9',
                      '100%': '#87d068',
                    }}
                  />
                </Tooltip>
              </Space>
              <Button 
                type="text" 
                danger 
                icon={<DeleteOutlined />}
                onClick={() => handleCancel(record)}
              >
                取消
              </Button>
            </Space>
          );
        }
        const detailStatus = record.task?.task_progress?.status;
        const isFailed = detailStatus === 'FAILURE';
        return (
          <Space style={{ width: '100%', justifyContent: 'space-between' }}>
            <Space>
              <Tooltip title={record.task?.task_progress?.message || '處理中...'}>
                <Tag color={isFailed ? 'red' : 'green'}>{detailStatus}</Tag>
              </Tooltip>
              {type === AI_ASSISTANT_TYPES.DOCUMENT_TRANSLATION && (
                <Tag color="blue">{getLanguageDisplay(record.data.target_language)}</Tag>
              )}
            </Space>
            <Space>
              {type === AI_ASSISTANT_TYPES.DOCUMENT_TRANSLATION ? (
                <Button type="primary" icon={<DownloadOutlined />} onClick={() => handleDownload(record, AI_ASSISTANT_FILE_TYPES.TRANSLATE)}>
                  下載翻譯
                </Button>
              ) : (
                <>
                  <Tag color="blue">{getDocumentType(record.data?.output_format)}</Tag>
                  <Button type="primary" icon={<DownloadOutlined />} onClick={() => handleDownload(record, AI_ASSISTANT_FILE_TYPES.MEDIA)}>
                    下載逐字稿
                  </Button>
                  <Button type="primary" icon={<DownloadOutlined />} onClick={() => handleDownload(record, AI_ASSISTANT_FILE_TYPES.SUMMARY)}>
                    下載分析
                  </Button>
                </>
              )}
              {/* <Button type="primary" icon={<MessageOutlined />}>
                討論
              </Button> */}
            </Space>
          </Space>
        );
      }
    },
    {
      title: '建立時間',
      width: '25%',
      dataIndex: 'event_timestamp',
      key: 'created_at',
      render: (timestamp) => formatTimestamp(timestamp)
    }
  ];

  const handleDownload = async (record, fileType) => {
    try {
      const file_content = record.task?.task_result?.[`source_${fileType}`]?.source_content;
      const file_url = record.task?.task_result?.[`source_${fileType}`]?.source_url;
      const original_file_name = record.task?.task_result?.[`source_${fileType}`]?.source_name;

      // 目前只有逐字稿需要轉換副檔名
      const file_name = fileType === AI_ASSISTANT_FILE_TYPES.MEDIA ? original_file_name.replace(/\.[^/.]+$/, '.txt') : original_file_name;

      if (!file_url && !file_content) {
        throw new Error('檔案內容不存在');
      }

      if (fileType === AI_ASSISTANT_FILE_TYPES.SUMMARY || fileType === AI_ASSISTANT_FILE_TYPES.TRANSLATE) {
        // 分析檔案直接使用 S3 下載連結
        window.open(file_url, '_blank');
      } else {
        // 逐字稿使用 blob 下載
        const blob = new Blob([file_content], { type: 'application/octet-stream' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = file_name;
        a.click();
        URL.revokeObjectURL(url);
      }
    } catch (error) {
      message.error('下載失敗: ' + error.message);
    }
  };

  return (
    <div className="ai-assistant-container">
      <h2>{config.title}</h2>
      
      <div className="section-container">
        <div className="section-header processing">
          <h3>{config.processingTitle}</h3>
        </div>
        <div className="section-content">
          <Table
            columns={getColumns(false)}
            dataSource={data.filter(item => item.status === 'PROCESSING')}
            rowKey="event_id"
            pagination={false}
            loading={loading}
            className="grouped-table processing-table"
            rowClassName="processing-row"
          />
        </div>
      </div>

      <div className="section-container">
        <div className="section-header completed">
          <h3>{config.completedTitle}</h3>
        </div>
        <div className="section-content">
          <Table
            columns={getColumns(true)}
            dataSource={data.filter(item => item.status === 'COMPLETED')}
            rowKey="event_id"
            pagination={false}
            loading={loading}
            className="grouped-table completed-table"
            rowClassName="completed-row"
          />
          {hasMore && (
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
              <Button onClick={handleLoadMore} loading={loading}>
                載入更多
              </Button>
            </div>
          )}
        </div>
      </div>

      <Button type="primary" className="ai-assistant-add-button" onClick={() => setIsModalVisible(true)}>
        新增項目
      </Button>

      {type === AI_ASSISTANT_TYPES.MEETING_SUMMARY && (
        <MeetingSummaryForm
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          onAfterSubmit={(result) => {
            setIsModalVisible(false);
            fetchData();
          }}
          user={user}
        />
      )}
      {type === AI_ASSISTANT_TYPES.DOCUMENT_TRANSLATION && (
        <TranslationForm
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          onAfterSubmit={(result) => {
            console.log('Form values:', result);
            setIsModalVisible(false);
            fetchData();
          }}
          user={user}
        />
      )}
    </div>
  );
};

export default AIAssistant;
