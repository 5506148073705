import React, { useContext } from 'react';
import { Button, Upload } from 'antd';
import { UploadOutlined, GoogleOutlined, LoadingOutlined, DeleteOutlined } from '@ant-design/icons';
import './index.scss';
import SettingContext from '../../contexts/settingProvider';

const SingleUpload = ({
  file,
  isUploading,
  onFileChange,
  onGoogleDriveClick,
  onDeleteFile,
  acceptTypes,
  disabled,
  googleDriveEnabled = true
}) => {
  const { dark } = useContext(SettingContext);
  return (
    <div className={`upload-section ${dark ? 'dark' : ''}`}>
      <div className="file-list">
        {file && (
          <div className="file-item">
            <span>{file.source_name}</span>
            {isUploading ? (
              <LoadingOutlined />
            ) : (
              <Button
                type="text"
                danger
                icon={<DeleteOutlined />}
                onClick={onDeleteFile}
              />
            )}
          </div>
        )}
        {!file && !isUploading && (
          <div className="empty-text">
            尚未上傳檔案
          </div>
        )}
        {isUploading && !file && (
          <div className="loading-indicator">
            <LoadingOutlined /> 上傳中...
          </div>
        )}
      </div>
      <div className="upload-buttons">
        <Upload
          accept={acceptTypes}
          maxCount={1}
          beforeUpload={() => false}
          onChange={onFileChange}
          showUploadList={false}
          disabled={disabled || isUploading}
        >
          <Button icon={<UploadOutlined />} disabled={disabled || isUploading}>選擇檔案</Button>
        </Upload>
        {googleDriveEnabled && (
          <Button 
            icon={<GoogleOutlined />}
            onClick={onGoogleDriveClick}
            disabled={disabled || isUploading}
          >
            Google Drive
          </Button>
        )}
      </div>
    </div>
  );
};

export default SingleUpload; 