import axios from '../utils/axiosConfig';
import { API_BASE_URL } from '../utils/config';

const BASE_URL = `${API_BASE_URL}/ai_tools`;

export const AI_ASSISTANT_TYPES = {
  MEETING_SUMMARY: 'summarize',
  DOCUMENT_TRANSLATION: 'translate'
};

export const AI_ASSISTANT_FILE_TYPES = {
  MATERIAL: 'material',
  MEDIA: 'media',
  SUMMARY: 'summary',
  TRANSLATE: 'translate'
};

// 獲取使用者的AI工具使用歷史記錄
export const getAIToolHistoryByUserEmail = async ({
  page = 1,
  pageSize = 10,
  orderBy = 'event_timestamp',
  orderDirection = 'DESC',
  userEmail,
  eventType
}) => {
  try {
    const response = await axios.get(`${BASE_URL}/ai_tool_history_by_user_email`, {
      params: {
        page,
        pageSize,
        order_by: orderBy,
        order_direction: orderDirection,
        user_email: userEmail,
        event_type: eventType
      }
    });
    return response.data;
  } catch (error) {
    console.error('獲取AI工具使用歷史記錄失敗:', error);
    throw error;
  }
};

// 取消AI工具使用歷史記錄
export const deleteAIToolHistory = async (eventId) => {
  try {
    const response = await axios.delete(`${BASE_URL}/ai_tool_history/${eventId}`);
    return response.data;
  } catch (error) {
    console.error('取消AI工具使用歷史記錄失敗:', error);
    throw error;
  }
};


// 會議記錄摘要
export const meetingSummarization = async (payload) => {
  try {
    const response = await axios.post(`${BASE_URL}/meeting_summarization`, payload);
    return response.data;
  } catch (error) {
    console.error('會議記錄摘要請求失敗:', error);
    throw error;
  }
};

// 文件翻譯
export const documentTranslation = async (payload) => {
  try {
    const response = await axios.post(`${BASE_URL}/document_translation`, payload);
    return response.data;
  } catch (error) {
    console.error('文件翻譯請求失敗:', error);
    throw error;
  }
};

// 獲取事件狀態
export const getEventStatus = async (eventId, eventProgress = 0) => {
  try {
    // 確保 eventProgress 在 0-100 之間
    const validatedProgress = Math.max(0, Math.min(100, eventProgress));
    
    const response = await axios.get(`${BASE_URL}/event_status/${eventId}`, {
      params: {
        event_progress: validatedProgress
      }
    });

    // 回傳的資料結構應包含:
    // {
    //   event_id: string,
    //   event_status: 'SUCCESS' | 'FAILURE' | 'STARTED' | 'PROCESSING' | 'UNKNOWN' | 'ERROR',
    //   event_message: string,
    //   event_progress: number,
    //   event_result?: any // 只有在 SUCCESS 狀態時才會有
    // }
    return response.data;
  } catch (error) {
    console.error('獲取事件狀態失敗:', error);
    throw error;
  }
};

// 下載任務結果
export const downloadTaskResult = async (eventId, fileType) => {
  try {
    const response = await axios.get(`${BASE_URL}/download/${eventId}/${fileType}`);
    return response.data;
  } catch (error) {
    console.error('下載任務結果失敗:', error);
    throw error;
  }
};
