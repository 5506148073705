import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Form, Input, Button, message, Divider, Select } from 'antd';
import { uploadAIToolsMaterial } from '../../api/s3';
import { getGoogleToken, getDriveFiles } from '../../api/ctrag';
import { documentTranslation } from '../../api/aiTools';
import './translationForm.scss';
import SingleUpload from '../../components/SingleUpload';
import LanguageSelector from '../../components/LanguageSelector';

const { TextArea } = Input;

const TranslationForm = ({ visible, onCancel, onAfterSubmit, user }) => {
    const [form] = Form.useForm();
    const [selectedLanguage, setSelectedLanguage] = useState('zh-TW');
    const [attachmentFile, setAttachmentFile] = useState(null);
    const [isAttachmentUploading, setIsAttachmentUploading] = useState(false);
    const [keywords, setKeywords] = useState([]);

    const loadPicker = useCallback(() => {
        window.gapi.load('picker', {
            callback: () => {
                // Picker API 加載完成
            },
        });
    }, []);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://apis.google.com/js/api.js';
        script.onload = () => loadPicker();
        document.body.appendChild(script);
    }, [loadPicker]);

    const createPicker = async () => {
        try {
            const googleAccessToken = await getGoogleToken();

            if (!googleAccessToken) {
                message.error('無法獲取 Google Access Token');
                setIsAttachmentUploading(false);
                return;
            }

            const allowedMimeTypes = [
                'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                'application/pdf',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'text/plain'
            ].join(',');

            const picker = new window.google.picker.PickerBuilder()
                .setOAuthToken(googleAccessToken)
                .setDeveloperKey(process.env.REACT_APP_GOOGLE_API_KEY)
                .setCallback((data) => pickerCallback(data))
                .enableFeature(window.google.picker.Feature.SUPPORT_TEAM_DRIVES)
                .addView(
                    new window.google.picker.DocsView()
                        .setEnableTeamDrives(true)
                        .setIncludeFolders(true)
                        .setMode(window.google.picker.DocsViewMode.LIST)
                        .setMimeTypes(allowedMimeTypes)
                )
                .setSelectableMimeTypes(allowedMimeTypes)
                .setLocale('zh-TW')
                .build();
            picker.setVisible(true);
        } catch (error) {
            console.error('創建 Google Picker 失敗:', error);
        }
    };

    const pickerCallback = async (data) => {
        if (data.action === window.google.picker.Action.PICKED) {
            const file = data.docs[0];
            await handleDriveFile(file);
        } else {
            setIsAttachmentUploading(false);
        }
    };

    const handleDriveFile = async (file) => {
        const newFile = {
            id: file.id,
            source_name: file.name,
            source_type: file.mimeType,
            source_source: 'drive',
            source_url: file.url,
            source_id: file.id,
            dispatchStatus: 'pending',
            dispatchMessage: '',
            status: 1,
        };

        try {
            setIsAttachmentUploading(true);
            const fileDetails = await getDriveFiles([file.id]);
            if (fileDetails && fileDetails.length > 0) {
                const detailedFile = fileDetails[0].file_info;
                const updatedFile = {
                    ...newFile,
                    source_name: detailedFile.name,
                    source_type: detailedFile.mimeType,
                    source_url: detailedFile.webViewLink,
                    size: detailedFile.size,
                    createdTime: detailedFile.createdTime,
                    status: detailedFile.status || 1,
                };

                if (attachmentFile) {
                    message.warning('只能上傳一個檔案，新檔案將取代舊檔案');
                }
                setAttachmentFile(updatedFile);
                form.setFieldValue('material_path', updatedFile);
            }
        } catch (error) {
            console.error('處理 Google Drive 文件選擇失敗:', error);
            message.error(`處理 Google Drive 文件失敗: ${error.message}`);
        } finally {
            setIsAttachmentUploading(false);
        }
    };

    const handleAttachmentFileChange = async (info) => {
        const file = info.file;
        if (attachmentFile) {
            message.warning('只能上傳一個檔案，新檔案將取代舊檔案');
        }

        setIsAttachmentUploading(true);

        try {
            const result = await uploadAIToolsMaterial([file], user.email);
            
            const newFile = { 
                source_name: file.name,
                source_type: file.type,
                source_source: 'local',
                source_url: result[0].source_info.s3_url
            };
            
            setAttachmentFile(newFile);
            form.setFieldValue('material_path', newFile);
            message.success('檔案上傳成功');
        } catch (error) {
            console.error('檔案上傳失敗:', error);
            message.error('檔案上傳失敗');
        } finally {
            setIsAttachmentUploading(false);
        }
    };

    const handleSubmit = async () => {
        try {
            const values = await form.validateFields();

            if (!attachmentFile || !values.material_path) {
                message.error('請上傳要翻譯的檔案');
                return;
            }

            if (isAttachmentUploading) {
                message.warning('請等待檔案上傳完成');
                return;
            }

            const formattedValues = {
                material_path: attachmentFile.source_url,
                additional_context: values.additional_context,
                target_language: values.target_language,
                constants_mapping: keywords.reduce((obj, keyword) => {
                    obj[keyword] = keyword;
                    return obj;
                }, {}),
            };
            const model = {
                model_provider: values.llm_model_provider || 'openai',
                model_level: values.llm_model_level || 'basic'
            }

            const result = await documentTranslation({ data: formattedValues, model: model });

            if (result.status === 200) {
                message.success('已成功送出翻譯請求');
                onAfterSubmit(result);
                form.resetFields();
                setAttachmentFile(null);
                setKeywords([]);
            } else {
                message.error(result.message || '送出翻譯請求失敗');
            }
        } catch (error) {
            if (error.errorFields) {
                message.error('請檢查表單填寫是否完整');
            } else {
                console.error('提交表單時發生錯誤:', error);
                message.error('處理請求時發生錯誤: ' + (error.message || '未知錯誤'));
            }
        }
    };

    const predefinedTags = ['微電能源','RFDME', 'EMS', 'PV O&M', 'Micro Electricity'];

    return (
        <Modal
            className="translation-modal"
            title="新增翻譯"
            open={visible}
            onCancel={onCancel}
            footer={[
                <Button key="cancel" onClick={onCancel}>
                    取消
                </Button>,
                <Button disabled={isAttachmentUploading} key="submit" type="primary" onClick={handleSubmit}>
                    開始執行
                </Button>
            ]}
            centered
            width={800}
        >
            <Form
                form={form}
                layout="vertical"
            >
                {/* 要翻譯的文件 */}
                <Form.Item
                    label="要翻譯的文件 (pdf, docx, pptx, xlsx, txt)"
                    name="material_path"
                    rules={[{ required: true, message: '請上傳要翻譯的文件' }]}
                    valuePropName="file"
                >
                    <SingleUpload
                        file={attachmentFile}
                        isUploading={isAttachmentUploading}
                        onFileChange={handleAttachmentFileChange}
                        onGoogleDriveClick={() => createPicker('attachment')}
                        onDeleteFile={() => {
                            setAttachmentFile(null);
                            form.setFieldValue('material_path', null);
                        }}
                        acceptTypes=".pdf,.docx,.pptx,.xlsx,.txt"
                        disabled={isAttachmentUploading}
                    />
                </Form.Item>

                {/* 補充文件資訊 */}
                <Form.Item
                    label="補充文件資訊"
                    name="additional_context"
                >
                    <TextArea
                        placeholder="針對這份文件的內容，可提供額外的補充資訊，讓翻譯的結果更準確。(Optional)"
                        rows={4}
                    />
                </Form.Item>

                {/* 目標語言 */}
                <Form.Item
                    label="目標語言"
                    name="target_language"
                    initialValue="zh-TW"
                >
                    <LanguageSelector
                        value={selectedLanguage}
                        onChange={value => setSelectedLanguage(value)}
                    />
                </Form.Item>

                {/* 不該被翻譯的關鍵字/專有名詞 */}
                <Form.Item
                    label="不該被翻譯的關鍵字/專有名詞"
                    name="constants_mapping"
                    initialValue={[]}
                >
                    <Select
                        mode="tags"
                        style={{ width: '100%' }}
                        placeholder="請選擇或輸入不該被翻譯的關鍵字"
                        onChange={value => setKeywords(value)}
                        value={keywords}
                    >
                        {predefinedTags.map(tag => (
                            <Select.Option key={tag} value={tag}>
                                {tag}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Divider />

                <div style={{ display: 'flex', gap: '16px' }}>
                    <Form.Item style={{ width: '120px' }} label="預設模型提供者" name="llm_model_provider" initialValue="openai">
                        <Select>
                            <Select.Option value="openai">OpenAI</Select.Option>
                            <Select.Option value="anthropic">Anthropic</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item style={{ width: '120px' }} label="預設模型等級" name="llm_model_level" initialValue="advanced">
                        <Select>
                            <Select.Option value="basic">基礎版</Select.Option>
                            <Select.Option value="medium">中階版</Select.Option>
                            <Select.Option value="advanced">進階版</Select.Option>
                        </Select>
                    </Form.Item>
                </div>
            </Form>
        </Modal>
    );
};

export default TranslationForm; 