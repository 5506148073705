/* eslint-disable react/prop-types */
import React, { createContext } from 'react';
import { ConfigProvider, theme } from 'antd';
import useLocalStorage from '../hooks/useLocalStorage';
import '../css/var.scss';

const SettingContext = createContext();
const colors = {
  background_color_dark: 'var(--background-color-dark)',
  background_color_lightdark: 'var(--background-color-lightdark)',
  background_color_exlightdark: 'var(--background-color-exlightdark)',
  primary_color: 'var(--primary-color)',
  color_light_01: 'var(--color-light-01)',
  color_light_02: 'var(--color-light-02)',
  color_light_03: 'var(--color-light-03)',
  color_light_05: 'var(--color-light-05)',
  color_light_1: 'var(--color-light-1)',
  color_light_2: 'var(--color-light-2)',
  color_light_3: 'var(--color-light-3)',
  color_light_4: 'var(--color-light-4)',
  color_white: 'var(--color-white)',
  color_border_base: 'var(--color-border-base)',
  color_option_selected: 'var(--color-option-selected)',
  color_tooltip_bg: 'var(--color-tooltip-bg)',
};
const baseTheme = {
  cssVar: true,
  hashed: false,
};

//十六进制颜色转换为带透明度的格式
const hexToRGBA = (hex, alpha = 1) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const apptheme = ({ dark, primaryColor, borderRadius }) => {
  if (dark) {
    return {
      ...baseTheme,
      algorithm: theme.darkAlgorithm,
      token: {
        colorPrimary: primaryColor,
        borderRadius,
        Layout: {
          bodyBg: colors.background_color_dark,
          footerBg: colors.background_color_dark,
          headerBg: colors.background_color_lightdark,
          siderBg: colors.background_color_lightdark,
          triggerBg: colors.background_color_lightdark,
          algorithm: false, // 启用算法
        },
        Card: {
          colorBgContainer: colors.background_color_lightdark,
          colorBorderSecondary: colors.color_light_03,
        },
        Input: {
          colorBgContainer: 'transprent',
          colorBorder: colors.color_light_1,
        },
        Button: {
          colorBgContainer: colors.background_color_lightdark,
          colorBorder: colors.color_light_1,
          colorLink: primaryColor,
          defaultGhostColor: colors.color_light_3,
          defaultGhostBorderColor: colors.color_light_1,
        },
        Table: {
          colorBgContainer: 'transprent',
          headerBg: 'transprent',
          borderColor: colors.color_light_1,
          colorTextHeading: colors.color_light_4,
          rowHoverBg: colors.color_light_02,
          colorLink: primaryColor,
          colorPrimary: primaryColor,
        },
        Select: {
          colorBgContainer: 'transprent',
          colorBgElevated: colors.background_color_lightdark,
          optionSelectedBg: colors.color_light_05,
        },
        Pagination: {
          colorBgContainer: 'transprent',
        },
        Dropdown: {
          colorBgElevated: colors.background_color_exlightdark,
        },
        Typography: {
          colorLink: primaryColor,
          colorPrimary: primaryColor,
          colorLinkHover: primaryColor,
        },
        InputNumber: {
          colorBgContainer: 'transprent',
          borderColor: colors.color_light_1,
        },
        Tooltip: {
          colorBgSpotlight: colors.color_tooltip_bg,
        },
        Menu: {
          itemBg: colors.background_color_lightdark,
          itemHoverBg: colors.color_light_05,
          itemActiveBg: colors.color_light_1,
          itemSelectedBg: hexToRGBA(primaryColor, 0.1),
          itemSelectedColor: primaryColor,
        },
        Checkbox: {
          colorBgContainer: 'transprent',
        },
        DatePicker: {
          colorBgContainer: 'transprent',
        },
        Modal: {
          contentBg: colors.background_color_lightdark,
          headerBg: colors.background_color_lightdark,
        },
      },
    };
  } else {
    return {
      ...baseTheme,
      token: {
        colorPrimary: primaryColor,
        borderRadius,
        Layout: {
          headerBg: colors.color_white,
          siderBg: colors.color_white,
          triggerBg: colors.color_white,
          algorithm: false, // 启用算法
        },
        Card: {
          colorBgContainer: colors.color_white,
          colorBorderSecondary: colors.color_white,
        },
        Button: {
          colorLink: primaryColor,
          defaultGhostColor: colors.color_white,
          defaultGhostBorderColor: colors.color_white,
        },
        Table: {
          colorLink: primaryColor,
          colorPrimary: primaryColor,
        },
        Typography: {
          colorLink: primaryColor,
          colorPrimary: primaryColor,
          colorLinkHover: hexToRGBA(primaryColor, 0.8),
          colorLinkActive: primaryColor,
        },
        Input: {
          colorBgContainer: colors.color_white,
          colorBorder: colors.color_border_base,
        },
        Tooltip: {
          colorBgSpotlight: colors.color_tooltip_bg,
        },
        Checkbox: {
          colorBgContainer: colors.color_white,
        },
        Select: {
          colorBgContainer: colors.color_white,
          colorBgElevated: colors.color_white,
          optionSelectedBg: colors.color_option_selected,
        },
        InputNumber: {
          colorBgContainer: colors.color_white,
        },
        DatePicker: {
          colorBgContainer: colors.color_white,
        },
        Modal: {
          contentBg: colors.color_white,
          headerBg: colors.color_white,
        },
        Dropdown: {
          colorBgElevated: colors.color_white,
        },
      },
    };
  }
};
export const SettingContextProvider = ({ children }) => {
  const [dark, setDark] = useLocalStorage('dark', 'true');
  const [primaryColor, setPrimaryColor] = useLocalStorage('primaryColor', '#009ccd');
  const [borderRadius, setBorderRadius] = useLocalStorage('borderRadius', 6);

  const defaultValue = {
    dark,
    setDark,
    primaryColor,
    setPrimaryColor,
    borderRadius,
    setBorderRadius,
  };

  return (
    <SettingContext.Provider value={defaultValue}>
      <ConfigProvider theme={apptheme({ dark, primaryColor, borderRadius })}>{children}</ConfigProvider>
    </SettingContext.Provider>
  );
};

export default SettingContext;
