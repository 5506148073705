import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import App from './App';
import { AuthContextProvider } from './contexts/authProvider';
import { SettingContextProvider } from './contexts/settingProvider';
import './utils/axiosConfig';
import 'antd/dist/reset.css';
import './css/main.scss';
import './css/dark.scss';
import './css/rwd.scss';
import './i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient();

root.render(
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <AuthContextProvider>
      <SettingContextProvider>
        <App />
      </SettingContextProvider>
    </AuthContextProvider>
  </QueryClientProvider>
  //</React.StrictMode>
);
